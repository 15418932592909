.vue-menu-item {
  color: #333;
  min-height: 2em;
  line-height: 2em;
  padding: 5px 10px;
  cursor: pointer;

  .md-icon {
    margin-right: 20px;
  }

  &:hover {
    color: #000;
    background: #f0f0f0;
  }
}

.vue-menu-content {
  .vue-menu, .vue-submenu {
    @extend .vue-menu-item;

    &:hover {
      &:after {
        background: #f0f0f0;
      }
    }

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      background: #fff;
      width: 20px;
      text-align: center;
      height: calc(2em + 10px);
      line-height: calc(2em + 10px);
      content: '›';
    }
  }
}