.grid {
  width: 100%;
  &.grid-container {
    display: flex;

    &.grid-horizontal-gutter-10 {
      margin-right: -5px;
      margin-left: -5px;

      & > * {
        padding-right: 5px;
        padding-left: 5px;
      }
    }

    &.grid-horizontal-gutter-20 {
      margin-right: -10px;
      margin-left: -10px;


      & > * {
        padding-right: 10px;
        padding-left: 10px;
      }
    }

    &.grid-vertical-gutter-10 {
      margin-top: -5px;
      margin-bottom: -5px;

      & > * {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    &.grid-vertical-gutter-20 {
      margin-top: -10px;
      margin-bottom: -10px;

      & > * {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  &.grid-column {
    flex-direction: column;
  }

  &.grid-child {

  }
}