.vue-menu-trigger {
  color: #333;
    z-index: 3;
    position: relative;
    cursor: pointer;
    user-select: none;

    &.disabled {
      color: rgba(0, 0, 0, 0.3);
      cursor: default;
    }

    &.expanded {
      
  }
}

.vue-menu {
  & > .vue-menu-trigger {
    background: #fff;
    top: 1px;
    padding: 5px 10px;
    margin: 5px 1px;

    &:not(.expanded):not(.disabled):hover {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 3pt;
      color: #000;
    }

    &.expanded {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

      &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 5px;
          z-index: 1;
          background: #fff;
          transform: translate(0, 100%);
          content: '';
        }
    }
  }
}