.vue-submenu {
  position: relative;

  &.disabled {
    color: rgba(0, 0, 0, 0.4);
    cursor: default;

    .vue-menu-trigger {
      color: inherit;

      &:hover {
        cursor: default;
      }
    }

    &:hover, &:hover:after {
      cursor: default;
      background: none;
    }
  }

  .vue-menu-content {
    left: 100%;
    top: 0;
  }
}