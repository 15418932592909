.vue-menu {
  float: left;
}

.vue-menu-backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
}