.slide-fade-enter-active {
  transition: transform .3s ease;
}
.slide-fade-leave-active {
  transition: transform .3s ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.vue-menu-content {
  background: #fff;
  position: absolute;
  z-index: 100;
  box-shadow: 0 3px 4px rgba(0,0,0,.14),
        0 3px 3px -2px rgba(0,0,0,.12);
  padding: 5px 0;
  min-width: 250px;

  .divider {
    height: 1px;
    background: rgba(0, 0, 0, 0.07);
    margin: 5px 0;
  }
}

.vue-submenu {
  & > .vue-menu-content {
    max-height: 80vh;
    overflow-y: auto;
    min-width: max-content;
  }
}