@import '../../assets/color';

.vue-menu-item {
  color: #333;
  min-height: 2em;
  line-height: 2em;
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s;

  &.disabled {
    color: rgba(0, 0, 0, 0.4);
    cursor: default;

    &:hover {
      color: rgba(0, 0, 0, 0.4);
      background: none;
    }
  }

  &.clickable:not(.disabled):hover {
    color: #000;
    background: #f0f0f0;
  }

  &.vue-menu-active {
    background: color('deep-purple', 'base');
    color: white;
  }

  .md-icon {
    margin-right: 20px;
  }
}

.vue-menu-content {
  .vue-menu, .vue-submenu {
    @extend .vue-menu-item;

    &:hover {
      &:after {
        background: #f0f0f0;
      }
    }

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      background: #fff;
      width: 20px;
      text-align: center;
      height: calc(2em + 10px);
      line-height: calc(2em + 10px);
      content: '›';
      transition: all 0.2s;
    }
  }
}